@charset "utf-8";
// 03__フォントサイズとラインハイトまとめて指定
// ----------------------------------------------------------
@mixin fz($size, $height: false, $weight: false) {
	font-size: #{$size}px;

	@if $weight {
		font-weight: $weight;
	}

	@if $height {
		line-height: $height / $size;
	}
}
@mixin fzr($size, $height: false, $weight: false) {
	font-size: #{$size / 10}rem;

	@if $weight {
		font-weight: $weight;
	}

	@if $height {
		line-height: $height / $size;
	}
}

// 06__media query
// ----------------------------------------------------------
@mixin media($size, $px: false) {
	@if ($size == "sm") {
		@media screen and (max-width: $breakPoint - 1) {
			@content;
		}
	} @else if ($size == "lg") {
		@media screen and (min-width: $breakPoint), print {
			@content;
		}
	}

	@if ($px) {
		@if ($size == "max-width") {
			@media screen and (max-width: $px) {
				@content;
			}
		} @else if ($size == "min-width") {
			@media screen and (min-width: $px) {
				@content;
			}
		} @else if ($size == "max-height") {
			@media screen and (max-height: $px) {
				@content;
			}
		} @else if ($size == "min-height") {
			@media screen and (min-height: $px) {
				@content;
			}
		}
	}
}

// 12__formスタイルリセット
// ----------------------------------------------------------
@mixin formReset() {
	input,
	button,
	textarea,
	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background: none;
		border: none;
		border-radius: 0;
		margin: 0;
		outline: none;
		padding: 0;
	}

	select::-ms-expand {
		display: none;
	}
}

// 13__ie11 (lg / sm)
// ----------------------------------------------------------
@mixin ie11($size: false) {
	html[data-browser="ie"][data-browserversion="11"] & {
		@if ($size) {
			@if ($size == "lg") {
				@media screen and (min-width: $breakPoint) {
					@content;
				}
			} @else if ($size == "sm") {
				@media screen and (max-width: $breakPoint - 1) {
					@content;
				}
			}
		} @else {
			@content;
		}
	}
}

// 14__edge (lg / sm)
// ----------------------------------------------------------
@mixin edge($size: false, $engine: "edgehtml") {
	html[data-browser="edge"][data-browserengine="#{$engine}"] & {
		@if ($size) {
			@if ($size == "lg") {
				@media screen and (min-width: $breakPoint) {
					@content;
				}
			} @else if ($size == "sm") {
				@media screen and (max-width: $breakPoint - 1) {
					@content;
				}
			}
		} @else {
			@content;
		}
	}
}

// 15__firefox (lg / sm)
// ----------------------------------------------------------
@mixin firefox($size: false) {
	html[data-browser="firefox"] & {
		@if ($size) {
			@if ($size == "lg") {
				@media screen and (min-width: $breakPoint) {
					@content;
				}
			} @else if ($size == "sm") {
				@media screen and (max-width: $breakPoint - 1) {
					@content;
				}
			}
		} @else {
			@content;
		}
	}
}

// 16__safari (lg / sm)
// ----------------------------------------------------------
@mixin safari($size: false) {
	@if ($size == "sm") {
		html[data-browser="mobile-safari"] & {
			@content;
		}
	} @else {
		html[data-browser="safari"] & {
			@content;
		}
	}
}

// 17__tablet (portrait / landscape)
// ----------------------------------------------------------
@mixin tablet($orientation: false) {
	@if ($orientation) {
		html[data-type="tablet"][data-orientation="#{$orientation}"] & {
			@content;
		}
	} @else {
		html[data-type="tablet"] & {
			@content;
		}
	}
}

// 18__ipados (portrait / landscape) ラップトップだけどタッチサポートしているデバイス全般
// ----------------------------------------------------------
@mixin ipados($orientation: false) {
	@if ($orientation) {
		html[data-type="laptop"][data-os="mac-os"][data-browser="safari"][data-touchsupport="true"][data-orientation="#{$orientation}"] & {
			@content;
		}
	} @else {
		html[data-type="laptop"][data-os="mac-os"][data-browser="safari"][data-touchsupport="true"] & {
			@content;
		}
	}
}
// 23__placeholder
// ----------------------------------------------------------
@mixin placeholder {
	&:placeholder {
		@content;
	}

	&:placeholder-shown {
		@content;
	}

	&::-webkit-input-placeholder {
		@content;
	}

	&:-moz-placeholder {
		@content;
	}

	&::-moz-placeholder {
		@content;
	}

	&:-ms-input-placeholder {
		@content;
	}
}

// 24__picture100
// ----------------------------------------------------------
@mixin picture100 {
	picture,
	source,
	img {
		@include object-fit(cover);

		height: 100%;
		width: 100%;
	}
}
