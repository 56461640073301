.c-titleA {
  text-align: center;
  font-size: 34px;
  font-weight: bold;
  color: #7a6a56;
  line-height: 1.5;
  @include media(sm) {
    font-size: 20px;
  }
}
.c-titleB {
  font-size: 28px;
  line-height: 1.5;
  color: #7a6a56;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  @include media(sm) {
    font-size: 18px;
  }
  img {
    margin-right: 20px;
    flex-shrink: 0;
  }
}