.l-hdr {
  padding: 25px 0;
  @include media(sm) {
    padding: 10px 0;
  }
  &-inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include media(sm) {
      display: block;
    }
    &__logo {
      text-align: center;
      @include media(sm) {
        margin-bottom: 20px;
      }
    }
    &__nav {
      display: flex;
      @include media(sm) {
        margin-bottom: 20px;
      }
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-right: 1px dotted #727171;
        width: 90px;
        height: 132px;
        @include media(sm) {
          width: 33.3333%;
        }
        &:first-child {
          border-left: 1px dotted #727171;
        }
        .icon {
          margin-bottom: 10px;
        }
        .text {
          font-size: 16px;
          line-height: 1.2;
          display: flex;
          justify-content: center;
          span {
            width: 1em;
            + span {
              margin-left: 3px;
            }
          }
        }
      }
    }
    &__cv {
      text-align: center;
      @include media(sm) {
        margin-bottom: 20px;
      }
      .tel {
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          font-size: 41px;
          line-height: 1;
          font-weight: bold;
          white-space: nowrap;
          img {
            margin-right: 10px;
            flex-shrink: 0;
          }
        }
      }
      .text {
        margin: 10px 0;
        a {
        font-size: 15px;
        line-height: 1;
        white-space: nowrap;
        }
      }
      .res {
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          color: white;
          background-color: #f8b745;
          height: 41px;
          border-radius: 10px;
          line-height: 1;
          img {
            margin-right: 10px;
            flex-shrink: 0;
          }
        }
      }
      .text02 {
        font-size: 14px;
        margin-top: 10px;
        line-height: 1;
      }
    }
    &__sns {
      display: flex;
      align-items: center;
      @include media(sm) {
        justify-content: center;
      }
      a {
        + a {
          margin-left: 20px;
        }
      }
    }
  }
}
