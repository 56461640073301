.l-ftr {
  &__pagetop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #9dc814;
      &:before {
        content: "";
        width: 15px;
        height: 15px;
        transform: translateY(26%) rotate(-45deg);
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
      }
    }
  }
  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9dc814;
    padding: 30px 20px;
    @include media(sm) {
    }
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: white;
      font-weight: bold;
      line-height: 1.5;
      text-align: center;
      @include media(sm) {
        width: 33.3333%;
      }
      img {
        margin-bottom: 20px;
      }
      + a {
        margin-left: 45px;
        @include media(sm) {
          margin-left: 0;
        }
      }
    }
  }
  &-main {
    background: url(../images/common/bg_gray.jpg);
    padding: 100px 0;
    text-align: center;
    @include media(sm) {
      padding: 50px 0;
    }
    &__logo {
      margin-bottom: 50px;
    }
    &__tel {
      a {
        display: inline-flex;
        align-items: center;
        font-size: 41px;
        line-height: 1;
        font-weight: bold;
        img {
          margin-right: 10px;
          flex-shrink: 0;
        }
      }
    }
    &__addr {
      font-size: 16px;
      margin-top: 20px;
    }
  }
  &__copy {
    text-align: center;
    font-size: 12px;
    color: #3e3a39;
    padding: 20px;
  }
}
