.c-inner {
  max-width: 1160px;
  margin: 0 auto;
  @include media(sm) {
    padding: 0 20px;
  }
}
.c-wrap {
  max-width: 1338px;
  margin: 0 auto;
  @include media(sm) {
    padding: 0 20px;
  }
}
@include media("min-width", "1025px") {
	.c-tab {
		display: none !important;
	}
}
@include media("max-width", "1024px") {
	.c-tab_none {
		display: none !important;
	}
}
@include media(lg) {
	.c-sp {
		display: none !important;
	}
}
@include media(sm) {
	.c-pc {
		display: none !important;
	}
}
@include media("min-width", "768px") {
	.c-xs {
		display: none !important;
	}
}
@include media("max-width", "767px") {
	.c-xs_none {
		display: none !important;
	}
}

.c-ofi {
	object-fit: cover;
	font-family: "object-fit: cover;, object-position: center;";
	width: 100%;
	height: 100%;
	object-position: center;
}
