.c-wysywig {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: 700;
	}
	h1 {
		margin-bottom: 30px;
		font-size: 30px;
	}
	h2 {
		margin-bottom: 0.5em;
		border-bottom: 1px solid #000;
		line-height: 1.5;
		padding-bottom: 5px;
		margin-bottom: 20px;
		margin-top: 40px;
	}
	h3 {
		margin-bottom: 0.5em;
		line-height: 1.5;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	h4 {
		margin-top: 30px;
		margin-bottom: 30px;
		line-height: 1.5;
	}
	h5 {
		margin-top: 30px;
		margin-bottom: 30px;
		line-height: 1.5;
	}
	h6 {
		margin-top: 30px;
		margin-bottom: 30px;
		line-height: 1.5;
	}
	p {
		margin-top: 10px;
		margin-bottom: 10px;
		font-size: 15px;
	}
	ul,
	ol {
		line-height: 1.5;
		margin-top: 20px;
		margin-bottom: 20px;
		list-style-position: outside;
		padding-left: 1em;
		li {
			+ li {
				margin-top: 5px;
			}
		}
	}
	ul {
		list-style-type: disc;
	}
	ol {
		list-style-type: decimal;
	}
	table {
		margin-top: 30px;
		margin-bottom: 30px;
		width: 100%;
		th {
			background: #ccc;
			color: #fff;
			border: 1px solid #000;
			white-space: nowrap;
			vertical-align: middle;
			padding: 10px 20px;
		}
		td {
			border: 1px solid #000;
			padding: 10px;
			vertical-align: middle;
		}
	}
	strong {
		font-weight: bold;
	}
	em {
		font-style: italic;
	}
	blockquote {
		position: relative;
		padding: 10px 20px;
		font-style: italic;
	}

	blockquote:before {
		display: inline-block;
		position: absolute;
		top: 0;
		left: -10px;
		content: "“";
		font-family: sans-serif;
		color: #ddd;
		font-size: 130px;
		line-height: 1;
	}

	blockquote:after {
		display: inline-block;
		position: absolute;
		bottom: -40px;
		right: 20px;
		text-align: center;
		content: "”";
		font-family: sans-serif;
		color: #ddd;
		font-size: 90px;
		line-height: 1;
	}

	blockquote p {
		position: relative;
		padding: 0;
		margin: 10px 0;
		z-index: 3;
		line-height: 1.7;
	}

	blockquote cite {
		position: relative;
		z-index: 3;
		display: block;
		text-align: right;
		font-size: 0.9em;
	}
	img {
		display: block;
		margin-top: 50px;
		margin-bottom: 50px;
	}
	.aligncenter {
		display: block;
		margin-left: auto !important;
		margin-right: auto !important;
	}
	.alignright {
		margin-left: auto;
	}
	.alignleft {
		margin-right: auto;
	}
	a {
		text-decoration: underline;
	}
}
