.c-icons {
	display: flex;
	justify-content: center;
	align-items: center;
	li {
		+ li {
			margin-left: 30px;
			@include media(sm) {
				margin-left: 10px;
			}
		}
	}
	&-wrap {
		position: relative;
		&__image {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			margin: auto 0;
			@include media(sm) {
				display: block;
				margin: 0 auto 20px;
				position: static;
			}
		}
	}
}
